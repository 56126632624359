<template>
    <div v-if="open" class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">

        <transition enter-class="transform opacity-0"
                    enter-active-class="transition ease-out duration-300"
                    enter-to-class="transform opacity-100"
                    leave-class="transform opacity-100"
                    leave-active-class="transition ease-in duration-200"
                    leave-to-class="transform opacity-0">

            <div x-show="open" class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

        </transition>

        <transition enter-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-active-class="transition ease-out duration-300"
                    enter-to-class="transform opacity-100 translate-y-0 sm:scale-100"
                    leave-class="transform opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="transition ease-in duration-200"
                    leave-to-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">

            <div v-if="open" v-on-clickaway="close" class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
                <div>
                    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <svg class="h-6 w-6 text-green-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            <slot name="header">
                                Some Success!
                            </slot>
                        </h3>
                        <div class="mt-2">
                            <p class="text-sm leading-5 text-gray-500">
                                <slot name="text">
                                    Some text will be written here!
                                </slot>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
                    <button @click="approve" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      <slot name="ApproveButton">
                                Approve
                      </slot>
                    </button>
                  </span>
                    <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                        <button @click="close" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          <slot name="cancelButton">
                              Cancel
                          </slot>
                        </button>
                  </span>
                </div>
            </div>

        </transition>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->

    </div>
</template>

<script>
    import {directive as onClickaway} from 'vue-clickaway'
    export default {
        directives: {
            onClickaway
        },
        name: "ApprovalModal",
        props: ['open'],
        methods: {
            close() {
                this.$emit("close")
            },
            approve(){
                this.$emit("approve")
                this.$emit("close")
            }
        }
    }
</script>

<style scoped>

</style>
