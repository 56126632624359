<template>
    <div v-if="loaded">
        <div class="bg-white shadow-xl-white overflow-hidden sm:rounded-lg">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Subscription Information
                </h3>
<!--                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">-->
<!--                    Personal details and application.-->
<!--                </p>-->
            </div>
            <div class="px-4 py-5 sm:p-0">
                <dl>
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Your plan
                        </dt>
                        <dd class="mt-1 text-sm leading-5 font-medium text-gray-900 sm:mt-0 sm:col-span-2">
                            {{ subscription.plan }}
                        </dd>
                    </div>
                    <div v-if="subscription.plan !== 'Jiruto Free'" class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Price
                        </dt>
                        <dd class="mt-1 text-sm leading-5 font-medium text-gray-900 sm:mt-0 sm:col-span-2">
                            {{ priceFormatted }}
<!--                            {{ subscription.price / 100 }}-->
                        </dd>
                    </div>

                    <div v-if="subscription.plan !== 'Jiruto Free'" class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <dt class="text-sm leading-5 font-medium text-gray-500">
                            Next Payment
                        </dt>
                        <dd class="mt-1 text-sm leading-5 font-medium text-gray-900 sm:mt-0 sm:col-span-2">
                            {{ dateFromTimestamp(subscription.nextBilling * 1000) }}
                        </dd>
                    </div>
                    <div class="mt-8 sm:mt-0 flex sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <div v-if="$store.getters.getSubscription === 'Jiruto Pro Monthly'" class="mt-2 flex-shrink-0">
                          <span class="inline-flex rounded-md shadow-sm">
                            <button @click="changePlanModal('proYearly')" type="button" class="relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-black hover:bg-indigo-500 hover:text-white focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">
                              Change to Pro Yearly
                                <span class="text-red-500 ml-2">Save 20%</span>
                            </button>
                          </span>
                        </div>
                        <div v-if="$store.getters.getSubscription === 'Jiruto Pro Yearly'" class="mt-2 flex-shrink-0">
                          <span class="inline-flex rounded-md shadow-sm">
                            <button @click="changePlanModal('proMonthly')" type="button" class="relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-black hover:bg-indigo-500 hover:text-white focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">
                              Change to monthly
                            </button>
                          </span>
                        </div>
                        <div v-if="subscription.plan === 'Jiruto Free'" class="mt-2 flex-shrink-0">
                          <span class="inline-flex rounded-md shadow-sm">
                            <router-link tag="button" :to="{name: 'upgrade'}" type="button" class="relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-500 hover:text-white focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">
                              Upgrade to Jiruto Pro!
                            </router-link>
                          </span>
                        </div>
                        <div v-if="!($store.getters.getSubscription === 'Jiruto Free')" class="ml-4 mt-2 flex-shrink-0">
                          <span class="inline-flex rounded-md shadow-sm">
                            <button @click="showCancelModal = true" class="relative inline-flex items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-black  hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700">
                              Cancel Subscription
                            </button>
                          </span>
                        </div>
                    </div>
                    <div class="sm:mt-0 flex sm:gap-4 sm:px-6 sm:pb-5">
                        <p v-if="goToSupport" class="text-red-500">*Something went wrong please contact support</p>
                    </div>


<!--                    <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">-->
<!--                        <dt class="text-sm leading-5 font-medium text-gray-500">-->
<!--                            About-->
<!--                        </dt>-->
<!--                        <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">-->
<!--                            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.-->
<!--                        </dd>-->
<!--                    </div>-->

<!--                    <div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">-->
<!--                        <dt class="text-sm leading-5 font-medium text-gray-500">-->
<!--                            Change Plan-->
<!--                        </dt>-->
<!--                        <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">-->
<!--                            <ul class="border border-gray-200 rounded-md">-->
<!--                                <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">-->
<!--                                    <div class="w-0 flex-1 flex items-center">-->
<!--                                        <svg class="flex-shrink-0 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                            <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd"/>-->
<!--                                        </svg>-->
<!--                                        <span class="ml-2 flex-1 w-0 truncate">-->
<!--                  resume_back_end_developer.pdf-->
<!--                </span>-->
<!--                                    </div>-->
<!--                                    <div class="ml-4 flex-shrink-0">-->
<!--                                        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">-->
<!--                                            Download-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                                <li class="border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">-->
<!--                                    <div class="w-0 flex-1 flex items-center">-->
<!--                                        <svg class="flex-shrink-0 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                            <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd"/>-->
<!--                                        </svg>-->
<!--                                        <span class="ml-2 flex-1 w-0 truncate">-->
<!--                  coverletter_back_end_developer.pdf-->
<!--                </span>-->
<!--                                    </div>-->
<!--                                    <div class="ml-4 flex-shrink-0">-->
<!--                                        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">-->
<!--                                            Download-->
<!--                                        </a>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </dd>-->
<!--                    </div>-->

                </dl>
            </div>
        </div>

        <alert-modal :open="showCancelModal" v-on:close="closeAlertModal" v-on:reset="cancelSubscription">
            <template v-slot:header>
                You're about to cancel your subscription!
            </template>
            <template v-slot:text>
<!--                Please confirm. After cancellation, you'll still have access to Pro features until <b>{{ dateFromTimestamp(subscription.nextBilling * 1000) }}</b>, after that you'll lose your Pro privileges: Private repositories will be made public and excess data will be deleted.-->
                Please confirm. After cancellation, you'll lose your Pro privileges: Private repositories will be made public and excess data will be deleted. You'll be refunded for your unused time. We hope you come back.
            </template>
            <template v-slot:button>
                I confirm. Please cancel my subscription.
            </template>
            <template v-slot:cancelButton>
                Abort
            </template>
        </alert-modal>

        <plan-approval-modal @keypress.esc="showApprovalModal = false" :open="showApprovalModal" v-on:close="showApprovalModal=false" v-on:approve="changePlan">
            <template v-slot:header>
                You're about to change to {{ plans[chosenPlan].name }}
            </template>
            <template v-slot:text>
                You'll be billed  {{ plans[chosenPlan].price}}. Please confirm.
            </template>
        </plan-approval-modal>

        <success-notification :open="showSuccessfulChangeNotification">
            <template v-slot:header>
                {{ successNotification.header }}
            </template>
            <template v-slot:text>
                {{ successNotification.text }}
            </template>
        </success-notification>



    </div>
</template>

<script>
    import Modal from '../components/ApprovalModal'
    import AlertModal from '../components/AlertModal'
    import * as axios from '../axios-auth'
    import * as fb from '../firebaseConfig'
    import {capitalize} from "../utils/helpers";
    import {dateFromTimestamp} from "../utils/helpers";
    import {mapGetters} from 'vuex'
    import SuccessNotification from "../components/ActionNotification";
    export default {
        name: "AccountSubscription",
        components: {
            SuccessNotification,
            alertModal: AlertModal,
            planApprovalModal: Modal,

        },
        data(){
            return {
                goToSupport: false,
                showApprovalModal: false,
                plans: {
                    proYearly: {
                        name: "Jiruto Pro Yearly",
                        price: "$90/year, which translates to $7.5 per month"
                    },
                    proMonthly: {
                        name: "Jiruto Pro Monthly",
                        price: "$9/month"
                    },
                    free: {
                        name: "Jiruto Free",
                    }
                },
                chosenPlan: "",
                subscription: {
                    plan: null,
                    nextBilling: null,
                    price: null,
                    created: null,
                    billingCycle: null,
                },
                successNotification: {
                    header: "",
                    text: ""
                },
                loaded: false,
                showCancelModal: false,
                showSuccessfulChangeNotification: false
            }
        },
        created(){
            this.fetchPlan()
        },
        computed: {
            ...mapGetters(['getUser']),
            price(){
                return this.getUser.subscription === 'Jiruto Pro Monthly' ? '$9/month' : '$90/year'
            },
            nextBillingFormatted(){
                let billing = new Date(this.subscription.nextBilling * 1000)
                return billing.toDateString()
            },
            priceFormatted(){
                return `$${this.subscription.price/100}/${this.subscription.billingCycle}`
            },
            shouldWaitForEndOfPeriod(){
                const currentPlan = this.subscription.plan
                const newPlan = this.plans[this.chosenPlan].name

                return ((currentPlan === 'Jiruto Pro Yearly' && newPlan === 'Jiruto Free') ||
                    (currentPlan === 'Jiruto Pro Yearly' && newPlan === 'Jiruto Pro Monthly') ||
                    (currentPlan === 'Jiruto Pro Yearly' && newPlan === 'Jiruto Pro Monthly')
                )
                // if (currentPlan === 'Jiruto Pro Yearly' && newPlan === 'Jiruto Free'){
                //     return true
                // }
                // if (currentPlan === 'Jiruto Pro Yearly' && newPlan === 'Jiruto Pro Monthly'){
                //     return true
                // }
                // return currentPlan === 'Jiruto Pro Yearly' && newPlan === 'Jiruto Pro Monthly';
            },
        },
        watch : {
            chosenPlan(newVal){
                if (newVal === "proMonthly") {
                    this.successNotification.header = "Plan Changed!";
                    this.successNotification.text = "Plan changed successfully to Jiruto Pro Monthly"
                }
                if(newVal === "proYearly") {
                    this.successNotification.header = "Plan Changed!";
                    this.successNotification.text = "Plan changed successfully to Jiruto Pro Yearly"
                }
                if(newVal === "free") {
                    this.successNotification.header = "Subscription Cancelled!";
                    this.successNotification.text = "Subscription cancelled successfully. You're now on Jiruto Free"
                }
            }
        },
        methods: {
            fetchPlan(){
                return new Promise((resolve, reject) => {
                    let loader = this.$loading.show({
                        onCancel: this.loaderCanceled,
                    });
                    // console.log("The user ID" , fb.auth.currentUser.uid)
                    axios.jigo.post('getSubscription', fb.auth.currentUser.uid, {headers: {"Content-Type": "text/plain"}}).then((resp) =>{
                        this.subscription = resp.data
                        // console.log("This Subscription: ", this.subscription)
                        this.loaded = true
                        loader.hide()
                        resolve()
                    }).catch((err) => {
                        loader.hide()
                        console.error("Error occurred while trying to fetch the subscription", err.response.data)
                        reject(err)
                    })
                })
            },

            changePlan(){
                const planRequest = {
                    uid: fb.auth.currentUser.uid,
                    email: fb.auth.currentUser.email,
                    plan: this.plans[this.chosenPlan].name,
                    waitForEndOfPeriod: this.shouldWaitForEndOfPeriod,
                };
                let loader = this.$loading.show({
                    onCancel: this.onCancel,
                });
                axios.jigo.post('changeSubPlan', planRequest, {validateStatus: (status) =>{
                        return status < 500}}).then((resp) => {
                            loader.hide()
                            if (resp.status === 201) {
                                this.$store.dispatch('getUserData')
                                    .then(() => {
                                        this.fetchPlan().then(() => {
                                            this.showSuccessfulChangeNotification = true;
                                            setTimeout(() => this.showSuccessfulChangeNotification = false, 2000)
                                        })
                                    })
                                    .catch((err) => console.error("Some error while fetching the user",err))
                            }
                }).catch((err) => {
                    loader.hide()
                    console.error("Something went wrong with changing the plan: ", err)
                    this.goToSupport = true
                })
            },
            capitalize: capitalize,
            dateFromTimestamp: dateFromTimestamp,
            closeAlertModal(){
                this.showCancelModal = false
            },
            cancelSubscription(){
                this.chosenPlan = "free"
                this.changePlan()
            },
            changePlanModal(plan){
                console.log("I'm here!")
                this.chosenPlan = plan
                this.showApprovalModal = true
            }
        }
    }
</script>

<style scoped>

</style>
